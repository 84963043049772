import React from 'react';

export const Privacy = () => {
    return (
        <div id='content' className='min-h-[75vh] mt-[20px]'>
            <h2 className='text-[14px] md:text-[20px]'>Cricket8</h2>
            <h1 className='text-[30px] md:text-[55px]'>Privacy Policy</h1>

            <div className='font-chakra font-[#fff] text-[14px] mt-[30px]'>
                <p className='pb-[20px]'>
                    At Cricket8.com, accessible from https://Cricket8.com, one of our main
                    priorities is the privacy of our visitors. This Privacy Policy
                    document contains types of information that is collected and recorded
                    by Cricket8.com and how we use it. If you have additional questions or
                    require more information about our Privacy Policy, do not hesitate to
                    contact us.
                </p>
                <p className='pb-[20px]'>
                    This Privacy Policy applies only to our online activities and is valid
                    for visitors to our website with regards to the information that they
                    shared and/or collect in Cricket8.com. This policy is not applicable
                    to any information collected offline or via channels other than this
                    website.
                </p>
                <p className='pb-[20px] bold'>Consent</p>
                <p className='pb-[20px]'>
                    By using our website, you hereby consent to our Privacy Policy and
                    agree to its terms.
                </p>
                <p className='pb-[20px]'>
                    Information we collectThe personal information that you are asked to
                    provide, and the reasons why you are asked to provide it, will be made
                    clear to you at the point we ask you to provide your personal
                    information.
                </p>
                <p className='pb-[20px]'>
                    If you contact us directly, we may receive additional information
                    about you such as your name, email address, phone number, the contents
                    of the message and/or attachments you may send us, and any other
                    information you may choose to provide.
                </p>
                <p className='pb-[20px]'>
                    When you register for an Account, we may ask for your contact
                    information, including items such as name, company name, address,
                    email address, and telephone number.
                </p>
                <p className='pb-[20px]'>How we use your information</p>
                <p className='pb-[20px]'>
                    We use the information we collect in various ways, including to:
                    <ul className='list-disc pl-[30px]'>
                        <li>Provide, operate, and maintain our website</li>
                        <li>Improve, personalize, and expand our website</li>
                        <li>Understand and analyze how you use our website</li>
                        <li>Develop new products, services, features, and functionality</li>
                        <li>
                            Communicate with you, either directly or through one of our
                            partners, including for customer service, to provide you with
                            updates and other information relating to the website, and for
                            marketing and promotional purposes
                        </li>
                        <li>Send you emails</li>
                        <li>Find and prevent fraud</li>
                    </ul>
                </p>
                <p className='pb-[20px] bold'>Log Files</p>
                <p className='pb-[20px]'>
                    Cricket8.com follows a standard procedure of using log files. These
                    files log visitors when they visit websites. All hosting companies do
                    this and a part of hosting services’ analytics. The information
                    collected by log files include internet protocol (IP) addresses,
                    browser type, Internet Service Provider (ISP), date and time stamp,
                    referring/exit pages, and possibly the number of clicks. These are not
                    linked to any information that is personally identifiable. The purpose
                    of the information is for analyzing trends, administering the site,
                    tracking users’ movement on the website, and gathering demographic
                    information.
                </p>
                <p className='pb-[20px] bold'>Cookies and Web Beacons</p>
                <p className='pb-[20px]'>
                    Like any other website, Cricket8.com uses ‘cookies’. These cookies are
                    used to store information including visitors’ preferences, and the
                    pages on the website that the visitor accessed or visited. The
                    information is used to optimize the users’ experience by customizing
                    our web page content based on visitors’ browser type and/or other
                    information.
                </p>
                <p className='pb-[20px] bold'>Google DoubleClick DART Cookie</p>
                <p className='pb-[20px]'>
                    Google is one of a third-party vendor on our site. It also uses
                    cookies, known as DART cookies, to serve ads to our site visitors
                    based upon their visit to www.website.com and other sites on the
                    internet. However, visitors may choose to decline the use of DART
                    cookies by visiting the Google ad and content network Privacy Policy
                    at the following URL – https://policies.google.com/technologies/ads
                </p>
                <p className='pb-[20px] bold'>Our Advertising Partners</p>
                <p className='pb-[20px]'>
                    Some of advertisers on our site may use cookies and web beacons. Our
                    advertising partners are listed below. Each of our advertising
                    partners has their own Privacy Policy for their policies on user data.
                    For easier access, we hyperlinked to their Privacy Policies below.
                </p>
                <p className='pb-[20px]'>
                    Google https://policies.google.com/technologies/ads
                </p>
                <p className='pb-[20px] bold'>Advertising Partners Privacy Policies</p>
                <p className='pb-[20px]'>
                    You may consult this list to find the Privacy Policy for each of the
                    advertising partners of Cricket8.com.
                </p>
                <p className='pb-[20px]'>
                    Third-party ad servers or ad networks uses technologies like cookies,
                    JavaScript, or Web Beacons that are used in their respective
                    advertisements and links that appear on Cricket8.com, which are sent
                    directly to users’ browser. They automatically receive your IP address
                    when this occurs. These technologies are used to measure the
                    effectiveness of their advertising campaigns and/or to personalize the
                    advertising content that you see on websites that you visit.
                </p>
                <p className='pb-[20px]'>
                    Note that Cricket8.com has no access to or control over these cookies
                    that are used by third-party advertisers.
                </p>
                <p className='pb-[20px] bold'>Third Party Privacy Policies</p>
                <p className='pb-[20px]'>
                    Cricket8.com’s Privacy Policy does not apply to other advertisers or
                    websites. Thus, we are advising you to consult the respective Privacy
                    Policies of these third-party ad servers for more detailed
                    information. It may include their practices and instructions about how
                    to opt-out of certain options.
                </p>
                <p className='pb-[20px]'>
                    You can choose to disable cookies through your individual browser
                    options. To know more detailed information about cookie management
                    with specific web browsers, it can be found at the browsers’
                    respective websites.
                </p>
                <p className='pb-[20px] bold'>GDPR Data Protection Rights</p>
                <p className='pb-[20px]'>
                    We would like to make sure you are fully aware of all of your data
                    protection rights. Every user is entitled to the following:
                </p>
                <p className='pb-[20px]'>
                    The right to access – You have the right to request copies of your
                    personal data. We may charge you a small fee for this service.
                </p>
                <p className='pb-[20px]'>
                    The right to rectification – You have the right to request that we
                    correct any information you believe is inaccurate. You also have the
                    right to request that we complete the information you believe is
                    incomplete.
                </p>
                <p className='pb-[20px]'>
                    The right to erasure – You have the right to request that we erase
                    your personal data, under certain conditions.
                </p>
                <p className='pb-[20px]'>
                    The right to restrict processing – You have the right to request that
                    we restrict the processing of your personal data, under certain
                    conditions.
                </p>
                <p className='pb-[20px]'>
                    The right to object to processing – You have the right to object to
                    our processing of your personal data, under certain conditions.
                </p>
                <p className='pb-[20px]'>
                    The right to data portability – You have the right to request that we
                    transfer the data that we have collected to another organization, or
                    directly to you, under certain conditions.
                </p>
                <p className='pb-[20px]'>
                    If you make a request, we have one month to respond to you. If you
                    would like to exercise any of these rights, please contact us.
                </p>
                <p className='pb-[20px] bold'>Delete your Account</p>
                <p className='pb-[20px]'>
                    What deleting your account means
                    <ol className='pl-[20px]'>
                        <li>
                            1. Your account will be permanently deleted and removed from our
                            servers
                        </li>
                        <li>
                            2. You’ll lose all the data and content in that account, like game
                            data, transactions, and preferences
                        </li>
                        <li>
                            3. You’ll lose the content you bought, like rubies and coins
                        </li>
                    </ol>
                </p>
                <p className='pb-[20px]'>
                    Steps on how to delete your Account
                    <ol className='pl-[20px]'>
                        <li>1. Go to your Account settings</li>
                        <li>2. Click Delete My Account</li>
                        <li>3. Follow the instructions to delete your account</li>
                    </ol>
                </p>
            </div>
        </div>
    );
};
export default Privacy;
